import React, { useEffect, useRef, useState } from 'react';
import './Plan.css';
import plan from './images/plan2.jpg';
import axios from 'axios';

const Plan = () => {
    const [monthlyPlans, setMonthlyPlans] = useState([]);
    const [halfYearlyPlans, setHalfYearlyPlans] = useState([]);
    const [yearlyPlans, setYearlyPlans] = useState([]);
    const headerRef = useRef(null);

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const [monthlyResponse, halfYearlyResponse, yearlyResponse] = await Promise.all([
                    axios.get('http://localhost:5000/api/getMonthlyPlans'),
                    axios.get('http://localhost:5000/api/gethalfPlan'), // Fixed URL
                    axios.get('http://localhost:5000/api/getYearlyPlans')
                ]);

                if (monthlyResponse.data.success) {
                    setMonthlyPlans(monthlyResponse.data.data);
                }

                if (halfYearlyResponse.data.success) {
                    setHalfYearlyPlans(halfYearlyResponse.data.data);
                }

                if (yearlyResponse.data.success) {
                    setYearlyPlans(yearlyResponse.data.data);
                }
            } catch (error) {
                console.error('Error fetching the plans data:', error);
            }
        };

        fetchPlans();

        const scrollFunction = () => {
            const header = headerRef.current;
            if (header) {
                if (window.scrollY > 50) {
                    header.style.backgroundColor = 'rgba(0,0,0,0.8)';
                } else {
                    header.style.backgroundColor = 'transparent';
                }
            }
        };

        window.addEventListener('scroll', scrollFunction);

        return () => {
            window.removeEventListener('scroll', scrollFunction);
        };
    }, []);

    const handleButtonClick = () => {
        window.location.href = 'tel:+917813949494';
    };

    const renderPlans = (plans) => {
        return plans.map((plan, index) => (
            <div className="plan" key={index}>
                <h3>{plan.Plan_Name}</h3>
                <p className="price">{plan.plan_charges}</p>
                <div className="planDetails">
                    <p><span>Installation Charges:</span>{plan.installation_charges}</p>
                    <p><span>Bandwidth:</span> {plan.bandwidth}</p>
                    <p><span>Data:</span> {plan.data}</p>
                    <p><span>Voice:</span> {plan.voice}</p>
                    <p><span>OTT Apps:</span>  {plan.ott_apps}</p>
                    <p><span>Live TV:</span>{plan.live_tv}</p>
                    <p><span>Resolution:</span> {plan.complaint_res_time}</p>
                    <p><span>Special Features:</span>{plan.special_feature}</p>
                </div>
                <button className="btn" onClick={handleButtonClick}>Select Plan</button>
            </div>
        ));
    };

    return (
        <>
            <section className="w3l-about-breadcrumb">
                <div className="breadcrumb-image-container">
                    <img src={plan} alt="Breadcrumb" className="breadcrumb-image" />
                    <div className="breadcrumb-text">
                        <h1 className='P'>Plans</h1>
                    </div>
                </div>
            </section>

            <section className="pricingPlans">
                <div className="container">
                    <div className="planSection">
                        <h2>Monthly Plans</h2>
                        <div className="plans">
                            {renderPlans(monthlyPlans)}
                        </div>
                    </div>
                    <div className="planSection">
                        <h2>Half-Yearly Plans</h2>
                        <div className="plans">
                            {renderPlans(halfYearlyPlans)}
                        </div>
                    </div>
                    <div className="planSection">
                        <h2>Yearly Plans</h2>
                        <div className="plans">
                            {renderPlans(yearlyPlans)}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Plan;
