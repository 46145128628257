import React, { useEffect } from 'react';
import Enjoy from './images/about 2.webp';
import BreadcrumbImage from './images/about.webp'; // Import the breadcrumb image
import './About.css'; 

function About() {
  useEffect(() => {
    const handleScroll = () => {
      console.log('Scrolled!');
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
       
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-image-container">
          <img src={BreadcrumbImage} alt="Breadcrumb" className="breadcrumb-image" />
          <div className="breadcrumb-text">
            <h1 className='A'>About Us</h1>
           
          </div>
        </div>
      </section>

      <section className="w3l-aboutblock1" id="about">
        <div className="midd-w3 py-5">
          <div className="container py-lg-5 py-md-3 py-2">
            <div className="row">
              <div className="col-lg-6 mb-lg-0 mb-md-5 mb-5 align-self">
                <h3 className="title-big mx-0">About SCUD Communication</h3>
                <p className="mt-md-4 mt-3">
                  SCUD Communication is committed to providing cutting-edge broadband internet services, ensuring high-speed connectivity and reliable performance for homes and businesses. With a focus on customer satisfaction, we deliver innovative solutions tailored to meet diverse needs.
                </p>
                <p className="mt-3">
                  Our team of experts is dedicated to enhancing your digital experience with robust and secure internet solutions. Whether you're looking for a reliable connection for your home or enterprise-grade solutions for your business, SCUD Communication is here to serve you.
                </p>
                <a href="contact" className="btn btn-primary btn-style mt-md-5 mt-4">Know More</a>
              </div>
              <div className="col-lg-6">
                <div className="position-relative">
                  <img src={Enjoy} alt="SCUD Communication" className="radius-image-full img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w3l-aboutblock2" id="stats">
        <div className="midd-w3 py-5">
          <div className="container py-lg-5 py-md-3 py-2">
            <div className="row">
              <div className="col-lg-6 mb-lg-0 mb-md-5 mb-4 align-self">
                <h3 className="title-left mx-0">Our Commitment to Excellence</h3>
                <p className="pt-lg-2 mt-md-4 mt-3">
                  At SCUD Communication, we take pride in our ability to deliver exceptional internet services that exceed industry standards. Our commitment to quality is reflected in our impressive track record of completed projects, satisfied clients, and award-winning designs.
                </p>
                <p className="mt-3">
                  We continuously strive to improve and innovate, ensuring that our clients receive the best possible service and support. Our team works tirelessly to provide solutions that are both effective and efficient, making us a leader in the connectivity sector.
                </p>
              </div>
              <div className="col-lg-6">
                <div className="progress-circles">
                  <div className="circle-container">
                    <div className="circle" data-dimension="200" data-text="85%" data-fontsize="36" data-percent="85" data-fgcolor="#30B455" data-bgcolor="#eee" data-width="15" data-bordersize="5" data-animationstep="2"></div>
                    <h4>Completed Projects</h4>
                  </div>
                  <div className="circle-container">
                    <div className="circle" data-dimension="200" data-text="90%" data-fontsize="36" data-percent="90" data-fgcolor="#30B455" data-bgcolor="#eee" data-width="15" data-bordersize="5" data-animationstep="2"></div>
                    <h4>Happy Clients</h4>
                  </div>
                  <div className="circle-container">
                    <div className="circle" data-dimension="200" data-text="95%" data-fontsize="36" data-percent="95" data-fgcolor="#30B455" data-bgcolor="#eee" data-width="15" data-bordersize="5" data-animationstep="2"></div>
                    <h4>Award-Winning Designs</h4>
                  </div>
                  <div className="circle-container">
                    <div className="circle" data-dimension="200" data-text="100%" data-fontsize="36" data-percent="100" data-fgcolor="#30B455" data-bgcolor="#eee" data-width="15" data-bordersize="5" data-animationstep="2"></div>
                    <h4>24/7 Support</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
