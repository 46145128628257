import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import Logo from './images/logoscud.png'

function Footer() {
  return (
    <footer className="w3l-footer-29-main">
      <div className="footer-29 py-5">
        <div className="container py-lg-4">
          <div className="row footer-top-29">
            <div className="col-lg-4 col-md-6 col-sm-7 footer-list-29 footer-1 pr-lg-5">
              <div className="footer-logo mb-4">
                <a className="navbar-brand" href="index.html">
                  <img 
                    src={Logo} 
                    alt="SCUD Communication Logo" 
                    style={{ height: '100px', width: '140px', marginLeft: '65px', marginTop:'-30px' }} 
                  />
                </a>
              </div>
              <p>
                SCUD Communication offers reliable and high-speed WiFi services. We are dedicated to providing top-notch connectivity solutions to our clients, ensuring seamless internet access for both personal and business needs. Our services are available in Jalgaon.
              </p>
              <div className="main-social-footer-29 mt-4">
                <a href="#facebook" className="facebook"><FaFacebook /></a>
                <a href="#twitter" className="twitter"><FaTwitter /></a>
                <a href="#instagram" className="instagram"><FaInstagram /></a>
                <a href="#linkedin" className="linkedin"><FaLinkedin /></a>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-5 col-6 footer-list-29 footer-2 mt-sm-0 mt-5">
              <ul>
                <h6 className="footer-title-29">Useful Links</h6>
                <li><a href="#about">About Us</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#pricing">Pricing Plans</a></li>
                <li><a href="#contact">Contact Us</a></li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-5 col-6 footer-list-29 footer-3 mt-lg-0 mt-5">
              <h6 className="footer-title-29">Resources</h6>
              <ul>
                <li><a href="#training">Training</a></li>
                <li><a href="#marketplace">Marketplace</a></li>
                <li><a href="#experts">Our Experts</a></li>
                <li><a href="#platform">Platform</a></li>
                <li><a href="#customers">Customers</a></li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-7 footer-list-29 footer-4 mt-lg-0 mt-5">
              <h6 className="footer-title-29">Latest Updates</h6>
              <div className="post1">
                <a href="#url" className="post-title">Enhancing Your Connectivity Experience</a>
                <p>Discover how SCUD Communication is revolutionizing internet services.</p>
              </div>
              <div className="post1 mt-4">
                <a href="#url" className="post-title">Our New WiFi Packages</a>
                <p>Check out our latest WiFi packages tailored for different needs.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* copyright */}
      <section className="w3l-copyright text-center">
        <div className="container">
          <p className="copy-footer-29">
            © 2024 SCUD Communication. All rights reserved. Design by Shree Digital Marketing Agency & Dipali Marathe
          </p>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
