import React , {useEffect}from 'react';
import cont from './images/scud 2.webp'
import './Contact.css'

function Contact() {
  useEffect(() => {
    const scrollFunction = () => {
      const header = document.getElementById('header');
      if (header) {
        if (window.scrollY > 50) {
          header.style.backgroundColor = 'rgba(0,0,0,0.8)';
        } else {
          header.style.backgroundColor = 'transparent';
        }
      }
    };

    window.addEventListener('scroll', scrollFunction);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', scrollFunction);
    };
  }, []);


  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission

    // Gather form data
    const name = event.target.w3lName.value;
    const email = event.target.w3lSender.value;
    const phone = event.target.w3lPhone.value;
    const subject = event.target.w3lSubject.value;
    const message = event.target.w3lMessage.value;

    // Format the WhatsApp message
    const whatsappMessage = `Hello, I am ${name}. My email is ${email} and my phone number is ${phone}. Subject: ${subject}. Message: ${message}`;

    // Redirect to WhatsApp with the pre-filled message
    const whatsappURL = `https://wa.me/917813949494?text=${encodeURIComponent(whatsappMessage)}`;
    window.location.href = whatsappURL;
  };

  return (
    <div>
      {/* Breadcrumbs */}
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-image-container">
          <img src={cont} alt="Breadcrumb" className="breadcrumb-image" />
          <div className="breadcrumb-text">
            <h1 className='c'>Contact Us</h1>
           
          </div>
        </div>
      </section>
      {/* //Contact breadcrumb */}

      {/* Contact Section */}
      <section className="w3l-contact-7 pt-5" id="contact">
        <div className="contacts-9 pt-lg-5 pt-md-4">
          <div className="container">
            <div className="top-map">
              <div className="row map-content-9">
                <div className="col-lg-8">
                  <p className="mb-4">Your email address will not be published. Required fields are marked *</p>
                  <form onSubmit={handleSubmit} className="text-right">
                    <div className="form-grid">
                      <input type="text" name="w3lName" id="w3lName" placeholder="Name*" required />
                      <input type="email" name="w3lSender" id="w3lSender" placeholder="Email*" required />
                      <input type="text" name="w3lPhone" id="w3lPhone" placeholder="Phone number*" required />
                      <input type="text" name="w3lSubject" id="w3lSubject" placeholder="Subject" />
                    </div>
                    <textarea name="w3lMessage" id="w3lMessage" placeholder="Message"></textarea>
                    <button type="submit" className="btn btn-primary btn-style mt-3">Submit</button>
                  </form>
                </div>
                <div className="col-lg-4 cont-details">
                  <address>
                    <h5>Our Office Address</h5>
                    <p><span className="fa fa-map-marker"></span>12/495, Samarth Colony Flat No. 3, Akshay Chembers Behind Agarwal Hospital, MJ College Area Jalgaon 425001</p>
                    <p><span className="fa fa-phone"></span><a href="tel:+91 7813949494"> +91 7813949494</a></p>
                    <p><a href="mailto:mail@example.com"><span className="fa fa-envelope"></span>support@scudcommunication.com</a></p>
                    <p><a href="mailto:support@example.com"><span className="fa fa-support"></span>support@scudcommunication.com</a></p>

                    <h5 className="mt-4 mb-3">Follow us on social media</h5>
                    <div className="main-social-footer-29">
                      <a href="#facebook" className="facebook"><span className="fa fa-facebook m-0"></span></a>
                      <a href="#twitter" className="twitter"><span className="fa fa-twitter m-0"></span></a>
                      <a href="#instagram" className="instagram"><span className="fa fa-instagram m-0"></span></a>
                      <a href="#linkedin" className="linkedin"><span className="fa fa-linkedin m-0"></span></a>
                    </div>
                  </address>
                </div>
              </div>
            </div>
          </div>
          <div className="map mt-5">
          <iframe
  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d29798.16335781258!2d75.556473!3d21.001838!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd90fb6ac324e33%3A0x956dd4d073ce8718!2sScud%20Communication%20Private%20Limited!5e0!3m2!1sen!2sus!4v1723795454652!5m2!1sen!2sus"
  width="600"
  height="450"
  style={{ border: 0 }}
  allowFullScreen
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>

          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
