import React, { useEffect } from 'react';
import './Home.css'; 
import wifi from './images/wifi.jpg';
import fam from './images/Internet-Provider.jpg';
import service from './images/service.webp';
import ott from './images/ott.webp';
import ott2 from './images/ott2.webp';

function Home() {

  useEffect(() => {
    function scrollFunction() {
      const element = document.getElementById('myElementId');
      if (element) {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          element.style.display = "block";
        } else {
          element.style.display = "none";
        }
      }
    }

    window.onscroll = scrollFunction;

    return () => {
      window.onscroll = null;
    };
  }, []);

  return (
    <div>
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-image-container">
          <img src={service} alt="Breadcrumb" className="breadcrumb-image" />
          <div className="breadcrumb-text">
            <h1>Services</h1>
          </div>
        </div>
      </section>
      
      <section className="w3l-services py-5" id="services">
        <div className="container py-lg-5 py-md-4">
          <div className="text-center mb-5">
            <h2 className="section-title">Our Services</h2>
            <p className="section-subtitle">Discover the diverse range of services we offer.</p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-card" style={{ '--animation-delay': '0s' }}>
                <div className="service-icon icon-broadband">
                  <i className="fas fa-broadcast-tower"></i>
                </div>
                <div className="service-content">
                  <h4 className="service-title">Broadband Internet Services</h4>
                  <p className="service-description">
                    Broadband is at the heart of modern communications, and at SCUD we need a broadband connectivity that’s powerful and cost-effective.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-card" style={{ '--animation-delay': '0.2s' }}>
                <div className="service-icon icon-leased-line">
                  <i className="fas fa-network-wired"></i>
                </div>
                <div className="service-content">
                  <h4 className="service-title">Internet Leased Line Services</h4>
                  <p className="service-description">
                    SCUD provides you with a high-quality, cost-competitive leased line service with outstanding operations and support services.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-card" style={{ '--animation-delay': '0.4s' }}>
                <div className="service-icon icon-ott">
                  <i className="fas fa-tv"></i>
                </div>
                <div className="service-content">
                  <h4 className="service-title">OTT and Live TV Content</h4>
                  <p className="service-description">
                    An over-the-top (OTT) media service is not just a streaming media service but also offers Live TV content directly to viewers via the internet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w3l-about py-5" id="about">
        <div className="container py-lg-5 py-md-4">
          <div className="text-center mb-5">
            <h2 className="section-title">About SCUD Communication</h2>
            <p className="section-subtitle">Learn more about our journey, mission, and team.</p>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div className="about-image">
                <img src={ott} alt="SCUD Communication Team" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className="about-image">
                <img src={ott2} alt="SCUD Communication Office" className="img-fluid" />
              </div>
            </div>
            <div className="col-12">
              <p className="about-description">
                At SCUD Communication, we are dedicated to delivering exceptional communication services to our clients. Our team is composed of industry experts who are passionate about technology and committed to providing top-notch service. We leverage the latest advancements to offer reliable and innovative solutions for your communication needs.
              </p>
            </div>
          </div>
        </div>
      </section>

      
    </div>
  );
}

export default Home;
