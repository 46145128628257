import React, { useEffect } from 'react';
import './Home.css'; 
import wifi from './images/scud 3.webp';
import fam from './images/scud 4.webp';
import { Carousel } from 'react-bootstrap';
import image from './images/banner img-Photoroom.png';
import ott from './images/ott.webp';
import ott2 from './images/ott2.webp';

function Home() {
  useEffect(() => {
    const cards = document.querySelectorAll('.service-card');

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
            observer.unobserve(entry.target); // Stop observing once the animation has been triggered
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    cards.forEach(card => {
      observer.observe(card);
    });
  }, []);

  return (
    <div>
      <Carousel className="home-carousel">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={image}
            alt="Broadband Services"
          />
          <Carousel.Caption className="carousel-caption-left">
            <h3 className="carousel-title">Broadband Services</h3>
            <p className="carousel-text">Reliable and fast internet connectivity for your home and office.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={fam}
            alt="Our Team"
          />
          <Carousel.Caption className="carousel-caption-left">
            <h3 className="carousel-title">Meet Our Team</h3>
            <p className="carousel-text">Experienced professionals dedicated to your service.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={wifi}
            alt="WiFi Services"
          />
          <Carousel.Caption className="carousel-caption-left">
            <h3 className="carousel-title">WiFi Services</h3>
            <p className="carousel-text">Seamless WiFi connectivity throughout your space.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      {/* Services Section */}
      <section className="w3l-services py-5" id="services">
        <div className="container py-lg-5 py-md-4">
          <div className="text-center mb-5">
            <h2 className="Our">Our Services</h2>
            <p className="sec">Discover the diverse range of services we offer.</p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-card" style={{ '--animation-delay': '0s' }}>
                <div className="service-icon icon-broadband">
                  <i className="fas fa-broadcast-tower"></i>
                </div>
                <div className="service-content">
                  <h4 className="service-title">Broadband Internet Services</h4>
                  <p className="service-description">
                    Broadband is at the heart of modern communications, and at SCUD we need a broadband connectivity that’s powerful and cost-effective.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-card" style={{ '--animation-delay': '0.2s' }}>
                <div className="service-icon icon-leased-line">
                  <i className="fas fa-network-wired"></i>
                </div>
                <div className="service-content">
                  <h4 className="service-title">Internet Leased Line Services</h4>
                  <p className="service-description">
                    SCUD provides you with a high-quality, cost-competitive leased line service with outstanding operations and support services.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="service-card" style={{ '--animation-delay': '0.4s' }}>
                <div className="service-icon icon-ott">
                  <i className="fas fa-tv"></i>
                </div>
                <div className="service-content">
                  <h4 className="service-title">OTT and Live TV Content</h4>
                  <p className="service-description">
                    An over-the-top (OTT) media service is not just a streaming media service but also offers Live TV content directly to viewers via the internet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About SCUD Communication Section */}
      <section className="w3l-about py-5" id="about">
        <div className="container py-lg-5 py-md-4">
          <div className="text-center mb-5">
            <h2 className="section-title">About SCUD Communication</h2>
            <p className="section-subtitle">Learn more about our journey, mission, and team.</p>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div className="about-image">
                <img src={ott} alt="SCUD Communication Team" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className="about-image">
                <img src={ott2} alt="SCUD Communication Office" className="img-fluid" />
              </div>
            </div>
            <div className="col-12">
              <p className="about-description">
                At SCUD Communication, we are dedicated to delivering exceptional communication services to our clients. Our team is composed of industry experts who are passionate about technology and committed to providing top-notch service. We leverage the latest advancements to offer reliable and innovative solutions for your communication needs.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* View More Section */}
      <section className="w3l-view-more py-5" id="view-more">
        <div className="container text-center">
          <a href="plan" className="btn btn-style btn-primary">OUR PLANS</a>
        </div>
      </section>
    </div>
  );
}

export default Home;
